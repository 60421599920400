import React, {useEffect} from "react";
import { Link } from "react-router-dom";

import Dashboard from "../../images/dashboard.png";

import "./Pricing.css";

const Pricing = () => {

    useEffect(() => {
        document.title = "SalesRuby | Pricing";
    }, []);

    return (
        <div>
            <section className="pricing-header">
                <div className="pricing-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 col-xl-5 align-self-center">
                                <div className="text-container">
                                    <h1>The Right Plan To Help You Exceed Your Sales Target</h1>
                                    <p className="p-small">SalesRuby is the leader in sales engagement. We help brands deliver value and create trust by connecting authentically and meaningfully with their customers. We’re here to help you connect with customers on their terms.</p>
                                    <Link to="/" className="btn-solid-lg pricing-btn">Request a demo</Link>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-7" >
                                <div className="image-wrapper">
                                    <div className="video-wrapper">
                                        <a className="popup-youtube" href={"https://www.youtube.com/watch?v=DS-2CdZHJGA"} datatype="video" data-effect="fadeIn">     
                                            <img className="img-fluid header-img" src={Dashboard} alt="alternative" />
                                            <span className="video-play-button">
                                                <span></span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pricing-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-container text-center">
                                <h2>Choose A Plan That’s Right For Your Business</h2>
                            </div>
                            {/* <div id="pricing-switch">
                                <span class="switch-label switch-label-monthly">Monthly</span>
                                <label class="switch" id="">
                                    <input type="checkbox" checked="checked" />
                                    <span class="slider"></span>
                                </label>
                                <span class="switch-label switch-label-yearly active">Annually 
                                    <span class="save-money">(Save 20%)</span>
                                </span>
                            </div> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="pricing-card">
                                <div className="card-head">
                                    <h5>Team</h5>
                                    <p>For your sales team</p>
                                    <h6>&#x20a6;100,000 <sup>/month</sup></h6>
                                </div>
                                <div className="card-content">
                                    <ul>
                                        <li>
                                            <label>
                                                Import prospects from your excel sheet directly into the cadence.
                                                <input type="checkbox" checked="checked" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                Import prospects from your excel.
                                                <input type="checkbox" checked="checked" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                Import prospects from your excel.
                                                <input type="checkbox" className="checked" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                Import prospects from your excel sheet directly into the cadence.
                                                <input type="checkbox" className="checked" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-button">
                                    <Link to="/" className="pricing-btn">Choose Plan</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="pricing-card-blue">
                                <div className="card-head-blue">            
                                    <span className="span">Most Popular</span>
                                    <h5>Team</h5>
                                    <p>For your sales team</p>
                                    <h6>&#x20a6;100,000 <sup>/month</sup></h6>
                                </div>
                                <div className="card-content-blue">
                                    <ul>
                                    <li>
                                            <label>
                                                Import prospects from your excel sheet directly into the cadence.
                                                <input type="checkbox" checked="checked" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                Import prospects from your excel.
                                                <input type="checkbox" checked="checked" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                Import prospects from your excel.
                                                <input type="checkbox" className="checked" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                Import prospects from your excel sheet directly into the cadence.
                                                <input type="checkbox" className="checked" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-button-blue">
                                    <Link to="/" className="pricing-btn">Choose Plan</Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="pricing-card">
                                <div className="card-head">
                                    <h5>Team</h5>
                                    <p>For your sales team</p>
                                    <h6>&#x20a6;100,000 <sup>/month</sup></h6>
                                </div>
                                <div className="card-content">
                                    <ul>
                                    <li>
                                            <label>
                                                Import prospects from your excel sheet directly into the cadence.
                                                <input type="checkbox" checked="checked" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                Import prospects from your excel.
                                                <input type="checkbox" checked="checked" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                Import prospects from your excel.
                                                <input type="checkbox" className="checked" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                        <li>
                                            <label>
                                                Import prospects from your excel sheet directly into the cadence.
                                                <input type="checkbox" className="checked" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-button">
                                    <Link to="/" className="pricing-btn">Choose Plan</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pricing-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-container text-center">
                                <h2>Frequently Asked Questions</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pricing-accordion">
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-head" id="headingOne">
                                        <h6 className="mb-0" data-toggle="collapse" data-target="#collapseOne" ariaExpanded="true" aria-controls="collapseOne">
                                            Is Sequentia a CRM?
                                        </h6>
                                    </div>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div className="card-body">
                                            Sequentia is much more than a CRM, it helps B2B sales team win more customers and exceed their sales target.
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                <div className="card">
                                    <div className="card-head" id="headingTwo">
                                        <h6 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" ariaExpanded="false" aria-controls="collapseTwo">
                                            How long does it take to start and do I get support by Sequentia?
                                        </h6>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                        <div className="card-body">
                                            Sequentia is much more than a CRM, it helps B2B sales team win more customers and exceed their sales target.
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                <div className="card">
                                    <div className="card-head" id="headingThree">
                                        <h6 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseThree" ariaExpanded="false" aria-controls="collapseThree">
                                            Is it possible to connect external programs or are there any integrations available?
                                        </h6>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div className="card-body">
                                            Sequentia is much more than a CRM, it helps B2B sales team win more customers and exceed their sales target. 
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                                <div className="card">
                                    <div className="card-head" id="headingFour">
                                        <h6 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseFour" ariaExpanded="false" aria-controls="collapseFour">
                                            How does Sequentia handle data privacy?
                                        </h6>
                                    </div>
                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                        <div className="card-body">
                                            Sequentia is much more than a CRM, it helps B2B sales team win more customers and exceed their sales target. 
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Pricing