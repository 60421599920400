import React, {useEffect} from 'react';
import { Link } from "react-router-dom";

import "./Contact.css";

const Contact = () => {

    useEffect(() => {
        document.title = "SalesRuby | Contact Us";
    }, []);

    return (
        <div>
            <section className="contact-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="text-container">
                                <h1>How Can We Help?</h1>
                                <p>Searching for recruitment or training support? Our team is ready to help <Link to="/" className="contact-link">here</Link>. Or check out our <Link to="/" className="contact-link">free eBooks</Link> to find best practices to exceed your sales target specially curated for you Or our <Link to="/" className="contact-link">FAQ</Link></p>
                                <p>Would you like to join our team? Check out open positions in our <Link to="/careers" className="contact-link">career center</Link>.</p>
                                <p>Do you have any other enquiries? Kindly, fill the form and we’ll be in touch soon.</p>
                            </div>
                        </div>
                        <div className="col-md-6">

                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Map Area Starts --> */}
            <section className="contact-1">
                <div className="container-fluid">
                    <div className="row no-gutters">
                        <div className="col-md-7">
                            <div id="mapBox" className="mapBox">
                            <iframe 
                                title="SalesRuby Limited"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.1634883399397!2d3.353850214094435!3d6.626605623796242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b9398188f07d1%3A0x8aa2cc5fd2afc47d!2sSalesruby%20Limited!5e0!3m2!1sen!2sng!4v1608195495854!5m2!1sen!2sng" 
                                width="600" 
                                height="450" 
                                frameborder="0" 
                                style={{ border: 0 }} 
                                allowfullscreen="" 
                                aria-hidden="false" 
                                tabindex="0">
                            </iframe>
                            </div>
                        </div>
                        <div className="col-md-5">
                                <form action="#" method="post" className="contact-form" ariarole="form" id="contact-form">
                                        <div className="form-group">
                                            <label for="exampleInputFullName1">Full Name <span>*</span></label>
                                            <input type="text" placeholder="Enter your full name" id="full_name" />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputPhoneNumber1">Phone Number <span>*</span></label>
                                            <input type="tel" placeholder="Enter your phone number"  id="phone_number" />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Email Address <span>*</span></label>
                                            <input type="email" placeholder="Enter your email address" id="email"  />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputCompany1">Company <span>*</span></label>
                                            <input type="text" placeholder="Enter your company name" id="company" />
                                        </div>
                                        <div className="form-group">
                                            <label for="exampleInputCompanyRole1">Company Name <span>*</span></label>
                                            <input type="text" placeholder="Enter your company role" id="company_role" />
                                        </div>
                                        <div className="form-group">
                                            <label for="help">How May We Help You <span>*</span></label>
                                            <textarea name="message" cols="20" rows="5" className="upLabel"  placeholder="Tell us anything" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Message'" />
                                        </div>
                                        <div className="form-group form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                            <label className="form-check-label check-label" for="exampleCheck1">
                                                By checking this box, you are consenting to receive future communication from SalesRuby.
                                            </label>
                                        </div>
                                        <button type="submit" className="">Contact Me</button>
                                </form>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Map Area End --> */}
        </div>
    )
}

export default Contact;