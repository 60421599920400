import React, {useEffect} from 'react';
import { Link } from "react-router-dom";
import "./About.css";

import Gallery1 from "../../images/about-gallery1.png";
import Gallery2 from "../../images/about-gallery2.png";
import Gallery3 from "../../images/about-gallery3.png";
import Bulb from "../../images/bulb.png";

import { FiArrowRight } from "react-icons/fi"

const About = () => {

    useEffect(() => {
        document.title = "SalesRuby | About SalesRuby";
    }, []);

    return (
        <div>
            <section className="about-header">
                <div className="about-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-container">
                                    <h1>We Are Revolutionalizing Effective Sales Strategy For Revenue Growth</h1>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="text-container">
                                    <p>SalesRuby is the leader in sales engagement. We help brands deliver value and create trust by connecting authentically and meaningfully with their customers. We’re here to help you connect with customers on their terms.</p> 
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="text-container">
                                    <p>SalesRuby is the leader in sales engagement. We help brands deliver value and create trust by connecting authentically and meaningfully with their customers. We’re here to help you connect with customers on their terms.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-2">
                <div className="container-fluid px-0 mx-0">
                    <div className="row no-gutters">
                        <div class="col-md-4">
                            <img src={Gallery1} className="about-images" alt="salesruby-pictures" />
                        </div>
                        <div class="col-md-4">
                            <img src={Gallery2} className="about-images" alt="salesruby-pictures" />
                        </div>
                        <div class="col-md-4">
                        <img src={Gallery3} className="about-images" alt="salesruby-pictures" />
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="about-3">
            <div className="about3-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 col-xl-6 align-self-center">
                                <div className="text-container">
                                    <h4>Our Driving Force</h4>
                                    <p className="about-p1">If your sales reps are wasting weeks or even months coaxing reluctant prospects to sign contracts, it may be time to take a closer look at your sales cycle. A slow sales cycle means your sales reps are working longer and harder to close every deal. This can impede your team’s ability to generate revenue by taking up the time and energy that could go into closing more deals.</p>
                                    <Link to="/" className="btn-solid-lg about-btn">Request a demo</Link>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="about-card">
                                            <h4>Excellence</h4>
                                            <p>See what your team members are atually doing day- to- day.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="about-card">
                                            <h4>High Customer Priority</h4>
                                            <p>See what your team members are atually doing day- to- day.</p>
                                        </div>
                                    </div>
                                </div><br/>
                            
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="about-card">
                                            <h4>Goal Oriented</h4>
                                            <p>See what your team members are atually doing day- to- day.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="about-card-blue">
                                            <h4>Result Focused</h4>
                                            <p>See what your team members are atually doing day- to- day.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-container text-center">
                                <h2>We Believe In The Value Of The Work We Do</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <p>If your sales reps are wasting weeks or even months coaxing reluctant prospects to sign contracts, it may be time to take a closer look at your sales cycle. A slow sales cycle means your sales reps are working longer and harder to close every deal. This can impede your team’s ability to generate revenue by taking up the time and energy that could go into closing more deals.</p>
                            <h6>
                                <FiArrowRight className="about-arrow" />
                                Placing Top Talents In Different Sectors Of Workplace
                            </h6>
                            <p>If your sales reps are wasting weeks or even months coaxing reluctant prospects to sign contracts, it may bes. <span>here</span> </p>
                            <h6>
                                <FiArrowRight className="about-arrow" />
                                We teach you top tier sales techniques to generate more revenue
                            </h6>
                            <p>If your sales reps are wasting weeks or even months coaxing reluctant prospects to sign contracts, it may bes. <span>here</span> </p>
                        </div>
                        <div className="col-md-6">
                        <img src={Bulb} className="about-icon" alt="salesruby-pictures" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-container text-center about5-box">
                                <h4>See Sequentia In Action</h4>
                                <p>Flawlessly executed sales plays with easy-to-follow SalesRuby Cadence. Share best practices across the team, personalize.</p>
                                <Link to="/" className="about5-btn">Request a demo</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About;