import React, {useEffect} from 'react';
import { Link } from "react-router-dom";

import Dashboard from "../../images/dashboard.png";
import Scale from "../../images/carbon_scale.png";
import Money from "../../images/carbon_money.png";
import User from "../../images/user-check.png";
import Eye from "../../images/eye.png";

import DashedLine1 from "../../images/Line 69.png";
import DashedLine2 from "../../images/Line 70.png";

import "./Home.css";

const Home = () => {
    
    useEffect(() => {
        document.title = "SalesRuby | Think and Drive Revenue";
    }, []);
    
    return (
        <React.Fragment>
            <section id="header" className="header">
                <div className="header-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 col-xl-5 align-self-center">
                                <div className="text-container">
                                    <h1>Convert More Prospects Into Closed Deals</h1>
                                    <p className="p-small">Sequentia helps scale the capabilities of your team from prospecting to deal closure. With Sequentia, your team of 
6 is equipped to sell like 20 driving aggressive 
revenue growth.</p>
                                    <Link to="/" className="btn-solid-lg home-btn">Request a demo</Link>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-7" >
                                <div className="image-wrapper">
                                    <div className="video-wrapper">
                                        <a className="popup-youtube" href={"https://www.youtube.com/watch?v=DS-2CdZHJGA"} datatype="video" data-effect="fadeIn">     
                                            <img className="img-fluid header-img" src={Dashboard} alt="alternative" />
                                            <span className="video-play-button">
                                                <span></span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="home-2">
                    <div className="container">
                        <div className="row row-grid">
                            <div className="col-md-3">
                                <div className="home-card">
                                    <div className="image-border">
                                        <img src={Scale} alt="alternative" className="home-img" />
                                    </div>
                                    <h4>Scale Outreach</h4>
                                    <p>Reach more buyers daily and multiply the capacity of your sale team</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="home-card">
                                    <div className="image-border">
                                        <img src={Money} alt="alternative" className="home-img" />
                                    </div>
                                    <h4>Manage Deals</h4>
                                    <p>Manage deals efficiently and increase closing by as much as 20%</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="home-card">
                                    <div className="image-border">
                                        <img src={Eye} alt="alternative" className="home-img" />
                                    </div>
                                    <h4>Gain Visibility</h4>
                                    <p>See what your team members are atually doing day- to- day </p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="home-card">
                                    <div className="image-border">
                                        <img src={User} alt="alternative" className="home-img" />
                                    </div>
                                    <h4>Automate Follow-Up</h4>
                                    <p>Never miss a task again. Never lose a deal for ineffective follow-up </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="home-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2>Accelerate Your Sales Cycle</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="home-imageContainer">
                                    <img className="img-fluid home3-img" src={Dashboard} alt="alternative" />
                                </div>
                            </div>
                            <div className="col-lg-5 align-self-center">
                                <div className="text-container">
                                    <p className="first-text">If your sales reps are wasting weeks or even months coaxing reluctant prospects to sign contracts, it may be time to take a closer look at your sales cycle. A slow sales cycle means your sales reps are working longer and harder to close every deal. This can impede your team’s ability to generate revenue by taking up the time and energy that could go into closing more deals.</p>
                                    <p className="second-text">It doesn’t necessarily have to be that way. Think of Sequentia as your all- round software that does all the dirty work for your team while they focus majorly on closing the deal. We help automate your sales process and provide data analytics you can leverage on to identify better sales techniques to incrementally speed up the sales process, leading to less wasted time and more delas closed.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="home-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Streamline Your Processes</h2>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: 10 + 'rem' }}>
                            <div className="col-md-5 align-self-center">
                                <div className="text-container1">
                                    <p className="first-text">OPTIMIZE</p>
                                    <h4>Execute Your Sales Strategy</h4>
                                    <p className="second-text">Sequentia ensures optimized emails, calls and follow- through across your entire sales team, resulting in more productivity, messaging that is consistently on- brand and ultimately, more revenue.</p>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="image-container1">
                                    <img src={Dashboard} className="img-fluid" alt="alternative" />
                                </div>
                            </div>
                        </div>
                        <div className="dashed-line1">
                            <img src={DashedLine1} alt="alternative"/>
                        </div>
                        <div className="row" style={{ marginBottom: 10 + 'rem' }}>
                            <div className="col-md-7">
                                <div className="image-container2">
                                    <img src={Dashboard} className="img-fluid" alt="alternative" />
                                </div>
                            </div>
                            <div className="col-md-5 align-self-center">
                                <div className="text-container2">
                                    <p className="first-text scale">SCALE</p>
                                    <h4>Scale Your Revenue Success</h4>
                                    <p className="second-text">Spend more time with more prospects and feel better prepared for every interaction. Sequentia eliminates unnecessary time wasters, automates trivial activities, and surfaces exactly what you need at the right time.</p>
                                </div>
                            </div>
                        </div>
                        <div className="dashed-line2">
                            <img src={DashedLine2} alt="alternative" />
                        </div>
                        <div className="row">
                            <div className="col-md-5 align-self-center">
                                <div className="text-container1">
                                    <p className="first-text">SELL- OUT</p>
                                    <h4>At A Glance Sales Activities Report</h4>
                                    <p className="second-text">Sequentia offers you the complete visibility you’ve always wanted. From activity reports to admin dashboards, monitor the health of your sales process with total confidence. Make the required optimization when necessary to upscale the growth of revenue.</p>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="image-container1">
                                    <img src={Dashboard} className="img-fluid" alt="alternative" />
                                </div>
                            </div>
                        </div>
                    </div> 
                </section>

                <section className="home-5">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-md-12 text-center">
                                <img className="img-fluid" src={Dashboard} alt="SalesRuby"/>
                            </div> */}
                            <div className="col-md-12">
                                <div className="text-container text-center home-5-box">
                                    <h4>See Sequentia In Action</h4>
                                    <p>Flawlessly executed sales plays with easy-to-follow SalesRuby Cadence. Share best practices across the team, personalize.</p>
                                    <Link to="/" className="home5-btn">Request a demo</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </React.Fragment>
    )
}

export default Home;