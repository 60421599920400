import React, {useCallback} from 'react';
import { useDropzone } from 'react-dropzone';
import "./DropZone.css";
 
function DropZone() {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
 
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p className="drag">Drag & drop to attach CV or
            <span className="span"> Select files to upload</span>
          </p>
      }
    </div>
  )
}

export default DropZone;