import React from 'react';
// import { Link } from "react-router-dom"; 

import "./Footer.css"; 

// import LinkedIn from "../../images/linkedin1.png";
import Facebook from "../../images/facebook.png";
import Twitter from "../../images/twitter.png";
import Logo from "../../images/salerubyLogo.png";
import { FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
    return (
            <section className="footer-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img src={Logo} className="footer-logo" alt="SalesRuby" />
                            <p>14a Amara-Olu Street, Ikeja, <br/> Lagos, Nigeria. <br/>sales@salesruby.com +2349070047684</p>
                        </div>
                        {/* <div className="blue-blocks1">
                            <img src={LeftAngle} alt="alternative" />
                        </div> */}
                        <div className="col-md-2">
                            <p className="list-head">Solutions</p>
                            <ul className="unorder-list">
                                <li className="list-items">
                                    <a href="https://rubyhiring.com" target="_blank" rel="noreferrer" className="footer-list">Ruby Hiring</a>
                                </li>
                                <li className="list-items">
                                    <a href="https://academy.salesruby.com" target="_blank" rel="noreferrer" className="footer-list">Ruby Academy</a>
                                </li>
                                <li className="list-items">
                                    <a href="https://therubyhub.com" target="_blank" rel="noreferrer" className="footer-list">Sales Leadership Conference</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <p className="list-head">Resources</p>
                            <ul className="unorder-list">
                                <li className="list-items">
                                    <a href="https://therubyhub.com" target="_blank" rel="noreferrer" className="footer-list">E-books</a>
                                </li>
                                <li className="list-items">
                                    <a href="https://therubyhub.com" target="_blank" rel="noreferrer" className="footer-list">Blog</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <p className="list-head">Company</p>
                            <ul className="unorder-list">
                                <li className="list-items">
                                    <a href="https://events.salesruby.com" target="_blank" rel="noreferrer" className="footer-list">Events</a>
                                </li>
                                <li className="list-items">
                                    <a href="/careers" className="footer-list">Careers</a>
                                </li>
                                <li className="list-items">
                                    <a href="/about" className="footer-list">About Us</a>
                                </li>
                                <li className="list-items">
                                    <a href="/contact" className="footer-list">Contact Us</a>
                                </li>
                                <li className="list-items">
                                    <a href="/pricing" className="footer-list">Pricing</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="blue-blocks2">
                        <img src={RightAngle} alt="alternative" />
                    </div> */}
                    <hr/>
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 text-center">
                            <p className="footer-right">SalesRuby. All Rights Reserved</p>
                        </div>
                        <div className="col-md-4 text-right">
                            <div className="mobileHide text-right">
                                <a href=' https://www.facebook.com/salesruby/?ref=br_rs' target='_blank' rel="noreferrer" >
                                    <img src={Facebook} alt="alternative" className="footer-img" />
                                </a>
                                <a href=' https://twitter.com/SalesRubyNG' target='_blank' rel="noreferrer" >
                                    <img src={Twitter} alt="alternative" className="footer-img" />
                                </a>
                                <a href='https://www.linkedin.com/company/salesruby/ ' target='_blank' rel="noreferrer" >
                                    <FaLinkedinIn alt="alternative" className="footer-img" style={{width: 30 + 'px', height: 23 + 'px'}} /> 
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4 text-center mt-2">
                            <div className="mobileShow text-center">
                                <a href=' https://www.facebook.com/salesruby/?ref=br_rs' target='_blank' rel="noreferrer" >
                                    <img src={Facebook} alt="alternative" className="footer-img" />
                                </a>
                                <a href=' https://twitter.com/SalesRubyNG' target='_blank' rel="noreferrer" >
                                    <img src={Twitter} alt="alternative" className="footer-img" />
                                </a>
                                <a href='https://www.linkedin.com/company/salesruby/ ' target='_blank' rel="noreferrer" >
                                    <FaLinkedinIn alt="alternative" className="footer-img" style={{width: 30 + 'px', height: 23 + 'px'}} /> 
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        
    )
}

export default Footer;