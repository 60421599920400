import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';

import Topbar from "./component/navbar/Navbar";
import Footer from "./component/footer/Footer";

import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Career from "./pages/careers/Career";
import Contact from "./pages/contact/Contact";
import Pricing from "./pages/pricing/Pricing";

const App = () => {
  return (
    <Router>
      <Topbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/careers" component={Career} />
          <Route path="/contact" component={Contact} />
          <Route path="/pricing" component={Pricing} />
        </Switch>
      <Footer />
    </Router>
  );
}

export default App;
