import React, { useEffect } from 'react';
import DropZone from "../../component/file_uploader/DropZone";
// import { Link } from "react-router-dom";

import "./Career.css";

import { FiAward } from "react-icons/fi";
import { FiMapPin } from "react-icons/fi";
import { FiSmile } from "react-icons/fi";
import { FiTrendingUp } from "react-icons/fi";

import CareerImg from "../../images/career-img.png";

const Career = () => {

    useEffect(() => {
        document.title = "SalesRuby | Careers at SalesRuby";
    }, []);

    return (
        <div>
            <section className="career-header">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>Join Our Team At SalesRuby</h1>
                            <p>SalesRuby is the leader in sales engagement. We help brands deliver value and create trust by connecting authentically and meaningfully with their customers. 
We’re here to help you connect with customers on their terms.</p><br/>
                            <a href="/careers" className="button">See Available Positions</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="career-1">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <h2>Why You Should Work With Us</h2>
                            <p>If your sales reps are wasting weeks or even months coaxing reluctant prospects to sign contracts, it may be time to take a closer look at your sales cycle.</p>
                        </div>
                        <div className="col-md-6">
                            <p>If your sales reps are wasting weeks or even months coaxing reluctant prospects to sign contracts, it may be time to take a closer look at your sales cycle. A slow sales cycle means your sales reps are working longer and harder to close every deal.</p>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <div className="career-column">
                                <FiSmile className="career-icon" size={30} />
                                <h6>Fun</h6>
                                <p>We understand the balance between work and personal life this translates to our intentional lively and positive work environment.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="career-column">
                                <FiMapPin className="career-icon" size={30} />
                                <h6>Accessibility</h6>
                                <p>Our office is located in the heart of Lagos which makes it easily accessible.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <div className="career-column">
                                <FiTrendingUp className="career-icon" size={30} />
                                <h6>Career Growth</h6>
                                <p>We structure our organisation in such a way that allows personal and career growth for team members.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="career-column">
                                <FiAward className="career-icon" size={30} />
                                <h6>Reward Driven</h6>
                                <p>We recognise hardwork and smartwork with obvious results.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src={CareerImg} alt="career-img" className="career-img" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="career-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h1>Open Positions</h1>
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-head" id="headingOne">
                                    <h2 className="mb-0" data-toggle="collapse" data-target="#collapseOne" ariaExpanded="true" aria-controls="collapseOne">
                                        Warehouse Manager
                                    </h2>
                                    </div>

                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscin.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim. 
                                    </div>
                                    </div>
                                    <hr />
                                </div>
                                <div className="card">
                                    <div className="card-head" id="headingTwo">
                                    <h2 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" ariaExpanded="false" aria-controls="collapseTwo">
                                        Motion Graphics Designer
                                    </h2>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div className="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscin.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim. 
                                    </div>
                                    </div>
                                    <hr />
                                </div>
                                <div className="card">
                                    <div className="card-head" id="headingThree">
                                    <h2 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseThree" ariaExpanded="false" aria-controls="collapseThree">
                                        Senior ASP.Net Developer
                                    </h2>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div className="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscin.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.                                     </div>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <p className="form-para">Kindly fill the form below to apply</p>
                            <form action="#" method="post" className="career-form" ariarole="form" id="career-form">
                                <div className="form-group">
                                    <label for="exampleInputFullName1">Full Name <span>*</span></label>
                                    <input type="text" placeholder="Enter your full name" id="full_name" />
                                </div>
                                <div className="form-group">
                                    <label for="exampleInputAddress1">Your Address <span>*</span></label>
                                    <input type="text" placeholder="Enter your home address" id="address"  />
                                </div>
                                <div className="form-group">
                                    <label for="exampleInputEmail1">Your Email Address <span>*</span></label>
                                    <input type="email" placeholder="Enter your email address" id="email"  />
                                </div>
                                <div className="form-group">
                                    <label for="exampleInputPhoneNumber1">Your Phone Number <span>*</span></label>
                                    <input type="tel" placeholder="Enter your phone number"  id="phone_number" />
                                </div>
                                <div className="form-group">
                                    <label for="exampleInputPosition1">Position You're Applying For <span>*</span></label>
                                    <select className="" name="career-role" placeholder="Select the position you’re applying for">
                                        <option value="">Select the position you're applying for</option>
                                        <option value="Frontend_dev">Senior Frontend Developer</option>
                                        <option value=".NET_dev">.NET Developer</option>
                                        <option value="MERN_dev">MERN Stack Developer</option>
                                        <option value="Business_dev">Business Growth Developer</option>
									</select>
                                </div>
                                <div className="form-group">
                                    <div className="card-upload">
                                        <div className="files_upload">
                                            <div className="files_upload_drag">
                                                <button type="button" className="files_upload_DragDrop" placeholder="Select the position you're applying for">
                                                    <input type="file" hidden name="files[]" multiple accept="image/*, video/*" className="files_input" />
                                                        <div className="files_upload_inner">
                                                            <DropZone />
                                                        </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Career